import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { apiCaller } from "../services/apiCaller";

function Index() {
  const navigate = useNavigate();
  let [buttonLoading, setButtonLoading] = useState(false);

  function CheckSign() {
    const formik = useFormik({
      validateOnBlur: false,
      validateOnChange: false,
      enableReinitialize: true,
      initialValues: {
        code: "",
      },
      validationSchema: Yup.object({
        code: Yup.mixed()
          .required("Unique Code are required!")
          .test("checkCode", "Unique Code not found!", async function (value) {
            let valid = true;
            setButtonLoading(true);
            await apiCaller
              .get(`/${window.atob(value)}`)
              .then((res) => {
                setButtonLoading(false);
              })
              .catch((err) => {
                formik.values.code = "";
                valid = false;
                setButtonLoading(false);
                console.log(err, value);
              });
            return valid;
          }),
      }),
      onSubmit: async (values, { resetForm }) => {
        navigate(`/sign/${values.code}`);
      },
    });

    return (
      <>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-info">
            <div className="row">
              <div className="col-sm">
                <div className="form-group">
                  <label
                    htmlFor=""
                    style={{ fontSize: "17px" }}
                    className="mb-1"
                  >
                    Unique Code
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your unique code"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.code}
                    id="code"
                  />

                  {formik.touched.code && formik.errors.code ? (
                    <div className="text-danger">{formik.errors.code}</div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <button
            className={`btn btn-primary btn-lg btn-block d-block mt-2 `}
            type="submit"
            style={{
              backgroundColor: "#9A2B59",
              border: "none",
              width: "100%",
            }}
            disabled={buttonLoading}
          >
            {buttonLoading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Check"
            )}
          </button>
        </form>
      </>
    );
  }

  return (
    <div
      class="modal fade show d-block"
      id="checkBooking"
      tabindex="-1"
      aria-labelledby="checkBooking"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="checkBooking">
              Sign your agreement
            </h5>
          </div>
          <div class="modal-body">{CheckSign()}</div>
        </div>
      </div>
    </div>
  );
}

export default Index;
