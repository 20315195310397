  import { ProgressBar, Step } from "react-step-progress-bar";
  import "./ProgressBar.css";
  const ProgressStepBar = ({ currentStep, totalSteps }) => {
    let progress = (currentStep / totalSteps) * 100;
    if (currentStep == 2) {
      progress = 50;
  } else if (currentStep == 1) {
    progress = 1;
  }

  return (
    <div
      className="mt-4"
      style={{ width: "80%", margin: "auto", marginBottom: "50px" }}
    >
      <ProgressBar percent={progress} filledBackground="#4B214B">
        {/* <Step transition="scale">
          {({ accomplished, index }) => (
            <div className="stepContainer">
              <div
                className={`indexedStep ${
                  accomplished ? "accomplished" : null
                }`}
                style={{ marginTop: "30px" }}
              >
                {accomplished ? <div className="CheckMark">L</div> : index + 1}
              </div>
              <p className="mt-1">Sign</p>
            </div>
          )}
        </Step> */}
        <Step transition="scale">
          {({ accomplished, index }) => (
            <div className="stepContainer">
              <div
                className={`indexedStep ${
                  accomplished ? "accomplished" : null
                }`}
                style={{ marginTop: "30px" }}
              >
                {accomplished ? <div className="CheckMark">L</div> : index + 1}
              </div>
              <p className="mt-1">Sign</p>
            </div>
          )}
        </Step>
        {/* <Step transition="scale">
          {({ accomplished, index }) => (
            <div className="stepContainer">
              <div
                className={`indexedStep ${
                  accomplished ? "accomplished" : null
                }`}
                style={{ marginTop: "30px" }}
              >
                {accomplished ? <div className="CheckMark">L</div> : index + 1}
              </div>
              <p className="mt-1">Review</p>
            </div>
          )}
        </Step> */}
      </ProgressBar>
    </div>
  );
};

export default ProgressStepBar;
