import Index from "../pages/Index";
import FormPage from "../pages/FormPage";
import NotFound from "../pages/NotFound";
import ReviewPage from "../pages/ReviewPage";
const { createBrowserRouter } = require("react-router-dom");
const { default: SignPage } = require("../pages/SignPage");

const router = createBrowserRouter([
  {
    path: "/sign/:id",
    element: <SignPage />,
  },
  {
    path: "/sign/form",
    element: <FormPage />,
  },
  {
    path: "/sign/review",
    element: <ReviewPage />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/",
    element: <Index />,
  },
]);

export default router;
