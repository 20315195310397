function NotFound() {
  return (
    <>
      <div className="container">
        <h1>404 - Page Not Found</h1>
        <p>Sorry, the page you are looking for could not be found.</p>
      </div>
    </>
  );
}

export default NotFound;
