import { useState } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import ProgressStepBar from "../../components/ProgressBar";
import { apiCaller } from "../../services/apiCaller";
import "./index.css";

function ReviewPage(props) {
  const [isMounted, setIsMounted] = useState(true);
  let { state } = useLocation();
  let [selectOption, setSelectOption] = useState(0);
  console.log(state);

  const handleIframeLoad = () => {
    setIsMounted(true);
  };

  function onChange(selectedOption) {
    setSelectOption(selectedOption.value);
    console.log("Option Selected: ", selectedOption.value + 1);
  }

  function contactSelect() {
    let arr = [];

    for (let i = 0; i < state.contact_info.length; i++) {
      arr.push({ value: i, label: `Contact Person ${i + 1}` });
    }

    return (
      <Select
        options={arr}
        defaultValue={arr[0]}
        className="mt-2 mb-3"
        onChange={onChange}
      />
    );
  }

  function Continue() {
    Swal.fire({
      title: "Signing your agreement",
      timerProgressBar: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    apiCaller
      .post(`/merge/${state.invoice_id}`, state)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        Swal.fire("Success", "Your agreement has been signed", "success").then(
          () => {
            window.location.href = "https://voffice.co.id";
          }
        );
      });
  }

  return (
    <>
      <div className="container">
        <ProgressStepBar currentStep={3} totalSteps={3} />
        <div className="row">
          <div className="col">
            <iframe
              src={`${process.env.REACT_APP_PDF_URL}/${state.invoice_id}.pdf`}
              className="iframe"
              onLoad={handleIframeLoad}
            ></iframe>
          </div>
          <div className="col">
            <div
              className="form-section border p-3 rounded"
              style={{ background: "white" }}
              ref={(node) => {
                if (node) {
                  node.style.setProperty("text-align", "left", "important");
                }
              }}
            >
              <h4 className="d-flex" style={{ justifyContent: "center" }}>
                Review your assignment
              </h4>

              <div className="mx-3 mt-4">
                {/* GENERAL INFORMATION */}
                <div>
                  <h5 className="d-flex justify-content-start">
                    A. GENERAL INFORMATION
                  </h5>
                  <div className="row">
                    <div className="col">
                      <div className="group">
                        <p
                          style={{
                            fontSize: "17px",
                            margin: "0",
                            padding: "0",
                          }}
                          className="mb-1"
                        >
                          Full Name :
                        </p>
                        <p
                          style={{
                            fontSize: "17px",
                            margin: "0",
                            padding: "0",
                          }}
                          className="mb-1"
                        >
                          {state.name}
                        </p>
                      </div>
                      <div className="group mt-3">
                        <p
                          style={{
                            fontSize: "17px",
                            margin: "0",
                            padding: "0",
                          }}
                          className="mb-1"
                        >
                          Company Name :
                        </p>
                        <p
                          style={{
                            fontSize: "17px",
                            margin: "0",
                            padding: "0",
                          }}
                          className="mb-1"
                        >
                          {state.company_name ? state.company_name : "-"}
                        </p>
                      </div>
                      <div className="group mt-3">
                        <p
                          style={{
                            fontSize: "17px",
                            margin: "0",
                            padding: "0",
                          }}
                          className="mb-1"
                        >
                          Phone Number :
                        </p>
                        <p
                          style={{
                            fontSize: "17px",
                            margin: "0",
                            padding: "0",
                          }}
                          className="mb-1"
                        >
                          {state.phone ? state.phone : "-"}
                        </p>
                      </div>
                      <div className="group mt-3">
                        <p
                          style={{
                            fontSize: "17px",
                            margin: "0",
                            padding: "0",
                          }}
                          className="mb-1"
                        >
                          Email Address :
                        </p>
                        <p
                          style={{
                            fontSize: "17px",
                            margin: "0",
                            padding: "0",
                          }}
                          className="mb-1"
                        >
                          {state.email ? state.email : "-"}
                        </p>
                      </div>
                    </div>

                    <div className="col">
                      <div className="group">
                        <p
                          style={{
                            fontSize: "17px",
                            margin: "0",
                            padding: "0",
                          }}
                          className="mb-1"
                        >
                          Address :
                        </p>
                        <p
                          style={{
                            fontSize: "17px",
                            margin: "0",
                            padding: "0",
                          }}
                          className="mb-1"
                        >
                          {state.address}
                        </p>
                      </div>
                      <div className="group mt-3">
                        <p
                          style={{
                            fontSize: "17px",
                            margin: "0",
                            padding: "0",
                          }}
                          className="mb-1"
                        >
                          Company Business Activity :
                        </p>
                        <p
                          style={{
                            fontSize: "17px",
                            margin: "0",
                            padding: "0",
                          }}
                          className="mb-1"
                        >
                          {state.company_business}
                        </p>
                      </div>
                      <div className="group mt-3">
                        <p
                          style={{
                            fontSize: "17px",
                            margin: "0",
                            padding: "0",
                          }}
                          className="mb-1"
                        >
                          Number of employees :
                        </p>
                        <p
                          style={{
                            fontSize: "17px",
                            margin: "0",
                            padding: "0",
                          }}
                          className="mb-1"
                        >
                          {state.employees}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* CONTACT PERSON */}
                <div>
                  <h5 className="d-flex justify-content-start mt-3">
                    B. CONTACT PERSON
                  </h5>
                  <p
                    className=""
                    style={{ fontSize: "17px", margin: "0px", padding: "0px" }}
                  >
                    Select the contact person information you want to check:
                  </p>
                  {contactSelect()}

                  <div className="contact-group mt-3">
                    <div className="row">
                      <div className="col">
                        <div className="group">
                          <p
                            style={{
                              fontSize: "17px",
                              margin: "0",
                              padding: "0",
                            }}
                            className="mb-1"
                          >
                            Contact person name :
                          </p>
                          <p
                            style={{
                              fontSize: "17px",
                              margin: "0",
                              padding: "0",
                            }}
                            className="mb-1"
                          >
                            {state.contact_info[selectOption].name}
                          </p>
                        </div>
                      </div>
                      <div className="col">
                        <div className="group">
                          <p
                            style={{
                              fontSize: "17px",
                              margin: "0",
                              padding: "0",
                            }}
                            className="mb-1"
                          >
                            Contact person phone number :
                          </p>
                          <p
                            style={{
                              fontSize: "17px",
                              margin: "0",
                              padding: "0",
                            }}
                            className="mb-1"
                          >
                            {state.contact_info[selectOption].phone}
                          </p>
                        </div>
                      </div>
                    </div>
                    <p
                      className="d-flex text-center justify-content-center mt-2"
                      style={{ marginBottom: "8px" }}
                    >
                      If you are not reachable, we will forward the message to
                      you by:
                    </p>
                    {state.contact_info[selectOption].otherContactEmail && (
                      <>
                        <p
                          className="d-flex text-center justify-content-center"
                          style={{ marginBottom: "3px" }}
                        >
                          Email :
                        </p>
                        <p className="d-flex text-center justify-content-center">
                          {state.contact_info[selectOption].otherContactEmail}
                        </p>
                      </>
                    )}
                    {state.contact_info[selectOption].otherContactWhatsapp && (
                      <>
                        <p
                          className="d-flex text-center justify-content-center"
                          style={{ marginBottom: "3px" }}
                        >
                          Whatsapp :
                        </p>
                        <p className="d-flex text-center justify-content-center">
                          {
                            state.contact_info[selectOption]
                              .otherContactWhatsapp
                          }
                        </p>
                      </>
                    )}

                    <p
                      className="d-flex text-center justify-content-center"
                      style={{ marginBottom: "8px" }}
                    >
                      Can we give the caller your other contact number if they
                      ask:
                    </p>
                    <div className="row justify-content-md-center">
                      <div className="col-md-auto">
                        <input
                          type="radio"
                          name={`contact_info[${selectOption}].caller`}
                          id={`callerYes${selectOption}`}
                          value="Yes"
                          class="form-check-input"
                          checked={
                            state.contact_info[selectOption].caller === "Yes"
                          }
                          disabled
                        />
                        <label
                          htmlFor={`callerYes${selectOption}`}
                          class="form-check-label"
                          style={{ marginLeft: "3px" }}
                        >
                          Yes
                        </label>
                      </div>
                      <div className="col-md-auto">
                        <input
                          type="radio"
                          name={`contact_info[${selectOption}].caller`}
                          id={`callerNo${selectOption}`}
                          value="No"
                          class="form-check-input"
                          checked={
                            state.contact_info[selectOption].caller === "No"
                          }
                          disabled
                        />
                        <label
                          htmlFor={`callerNo${selectOption}`}
                          class="form-check-label"
                          style={{ marginLeft: "3px" }}
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {/* MAIL HANDLING  */}
                <div>
                  <h5 className="d-flex justify-content-start mt-3">
                    C. MAIL HANDLING
                  </h5>

                  <div className="row">
                    <div className="col">
                      <div className="group">
                        <p
                          style={{
                            fontSize: "17px",
                            margin: "0",
                            padding: "0",
                          }}
                          className="mb-1"
                        >
                          Email :
                        </p>
                        <p
                          style={{
                            fontSize: "17px",
                            margin: "0",
                            padding: "0",
                          }}
                          className="mb-1"
                        >
                          {state.mail_handling.email}
                        </p>
                      </div>
                    </div>

                    <div className="col">
                      <div className="group">
                        <p
                          style={{
                            fontSize: "17px",
                            margin: "0",
                            padding: "0",
                          }}
                          className="mb-1"
                        >
                          Whatsapp Number :
                        </p>
                        <p
                          style={{
                            fontSize: "17px",
                            margin: "0",
                            padding: "0",
                          }}
                          className="mb-1"
                        >
                          {state.mail_handling.whatsapp}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* OTHER CONTACT PERSON */}

                <div>
                  <h5 className="d-flex justify-content-start mt-3">
                    D. OTHER CONTACT PERSON
                  </h5>
                  <div>
                    <div className="row">
                      <div className="col">
                        <div className="group">
                          <p
                            style={{
                              fontSize: "17px",
                              margin: "0",
                              padding: "0",
                            }}
                            className="mb-1"
                          >
                            Name :
                          </p>
                          <p
                            style={{
                              fontSize: "17px",
                              margin: "0",
                              padding: "0",
                            }}
                            className="mb-1"
                          >
                            {state.other_contact.name}
                          </p>
                        </div>

                        <div className="group mt-3">
                          <p
                            style={{
                              fontSize: "17px",
                              margin: "0",
                              padding: "0",
                            }}
                            className="mb-1"
                          >
                            Whatsapp Number :
                          </p>
                          <p
                            style={{
                              fontSize: "17px",
                              margin: "0",
                              padding: "0",
                            }}
                            className="mb-1"
                          >
                            {state.other_contact.whatsapp}
                          </p>
                        </div>
                      </div>

                      <div className="col">
                        <div className="group">
                          <p
                            style={{
                              fontSize: "17px",
                              margin: "0",
                              padding: "0",
                            }}
                            className="mb-1"
                          >
                            Phone Number :
                          </p>
                          <p
                            style={{
                              fontSize: "17px",
                              margin: "0",
                              padding: "0",
                            }}
                            className="mb-1"
                          >
                            {state.other_contact.phone}
                          </p>
                        </div>

                        <div className="group mt-3">
                          <p
                            style={{
                              fontSize: "17px",
                              margin: "0",
                              padding: "0",
                            }}
                            className="mb-1"
                          >
                            Email Address :
                          </p>
                          <p
                            style={{
                              fontSize: "17px",
                              margin: "0",
                              padding: "0",
                            }}
                            className="mb-1"
                          >
                            {state.other_contact.email}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              onClick={Continue}
              style={{ padding: "5px" }}
              className="btn mx-3 my-3"
            >
              Save & Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReviewPage;
