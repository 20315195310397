import { useEffect, useRef, useState } from "react";
import ReactLoading from "react-loading";
import { useNavigate, useParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import Swal from "sweetalert2";
import ProgressStepBar from "../../components/ProgressBar";
import { apiCaller } from "../../services/apiCaller";
import "./App.css";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'; // Import PDF annotation styles
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
document.body.style = "background: #F5F6F8";
function SignPage(props) {
  const [openModel, setOpenModal] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const sigCanvas = useRef();
  const [penColor, setPenColor] = useState("black");
  const [imageURL, setImageURL] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [base64, setBase64] = useState("");
  const [pdfLoc, setPDFLoc] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [position, setPosition] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  let { id } = useParams();
  let navigate = useNavigate();
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1.0); // Initial scale is 1.0 (100%)
  const [isMobile, setIsMobile] = useState(false);
  const [nameError, setNameError] = useState(""); // State for name validation error
  const [positionError, setPositionError] = useState("");

  const checkIsMobile = () => {
    setIsMobile(window.innerWidth <= 768); // Adjust the width threshold as needed
  };

  useEffect(() => {
    checkIsMobile(); // Check initial device type on component mount
    window.addEventListener("resize", checkIsMobile); // Listen for window resize events

    return () => {
      window.removeEventListener("resize", checkIsMobile); // Remove the resize event listener on unmount
    };
  }, []);

  const handleZoomIn = () => {
    if (scale < 2.0) {
      // Limit the maximum scale to 200%
      console.log(scale);
      setScale(scale + 0.1);
    }
  };
  const handleZoomOut = () => {
    if (scale > 0.5) {
      // Limit the minimum scale to 50%
      setScale(scale - 0.1);
    }
  };
  const colors = ["black", "green", "red", "yellow", "blue", "purple"];
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const create = () => {
    const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    console.log(URL);
    sigCanvas.current.fromDataURL(URL, "image/png");
    setImageURL(URL);
    setOpenModal(false);
  };
  const SaveSign = async () => {

    if (!isChecked) {
      Swal.fire("Warning", "Please check the agreement checkbox before saving and submitting.", "warning");
      return; // Stop further execution if the checkbox is not checked
    }
    if (!name.trim() || !/^[A-Za-z\s]+$/.test(name.trim())) {
      setNameError("Name is required and should only contain alphabet characters.");
      return;
    } else {
      setNameError(""); // Clear the error if name is valid
    }

    // Validate Position
    if (!position.trim() || !/^[A-Za-z\s]+$/.test(position.trim())) {
      setPositionError("Position is required and should only contain alphabet characters.");
      return;
    } else {
      setPositionError(""); // Clear the error if position is valid
    }
    const confirmSave = await Swal.fire({
      title: "Are you sure?",
      icon: "warning", 
      confirmButtonText: 'Yes <i class="fa fa-arrow-right"></i>',
      showCancelButton: true,
      cancelButtonColor: "red",
    });

    if (confirmSave.isConfirmed) {
      let obj = {
        invoice_id: invoiceId,
        filename: imageURL,
        pdfLocation: pdfLoc,
        pic_name: name, // Add Name to the object
        pic_position: position, // Add Position to the object
      };
      setIsLoading(true)
      apiCaller
        .post(`/digitalsign/${obj.invoice_id}`, obj)
        .then((res) => {
          Swal.fire("Success", "Your agreement has been signed", "success").then(
            () => {
              window.location.reload()
              window.location.href = "https://voffice.co.id";

            }
          );
        })
        .catch((err) => {
          console.log(err.response.data.message);
          Swal.fire("Error", (err.response.data.message), "error").then(
            () => {
              setIsLoading(false)
              return   true
            }
          );
        })

      // navigate("/sign/form", { state: obj });
    }
  };


  // useEffect(() => {
  //   if (!isMounted) {
  //     Swal.fire({
  //       title: "Please Wait",
  //       timerProgressBar: true,
  //       allowEscapeKey: false,
  //       allowOutsideClick: false,
  //       didOpen: () => {
  //         Swal.showLoading();
  //       },
  //     });
  //   } else {
  //     Swal.close();
  //   }
  // }, [isMounted]);

  useEffect(() => {

    apiCaller
      .get(`/getSign/${(id)}`)
      .then((res) => {
        console.log(res.data.invoice_id);
        setIsLoading(false);
        setIsMounted(false);
        setInvoiceId(res.data.invoice_id);
        if (res.data.product_category == 1) {
          setCategory("so")
        } else if (res.data.product_category == 7){
          setCategory("meetingroom")
        }else if (res.data.product_category == 13){
          setCategory("coworking")
        }
        else {
          setCategory("vo")
        }
      })
      .catch((err) => {
        Swal.fire("Error", "No Data Found!", "error").then(() =>
          navigate("/")
        );
        console.log(err);
      });


  }, []);

  if (isLoading) {
    return (
      <div
        className="text-center"
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <ReactLoading
          color="#808080"
          type="bubbles"
          height={"100px"}
          width={"150px"}
        />
      </div>
    );
  } else {
    return (
      <div className="container">
        {/* <ProgressStepBar currentStep={1} totalSteps={3} /> */}
        <div className="row">
          {isMobile ? (
            <div className="col-md-6 order-md-1">
              <div className="zoom-controls">
                <button className="zoom-button" onClick={handleZoomIn}>Zoom In</button>
                <button className="zoom-button" onClick={handleZoomOut}>Zoom Out</button>
              </div>
              <div className="pdf-container" style={{ backgroundColor: '#f0f0f0' }}>
                <Document
                  file={`${process.env.REACT_APP_AGREEMENT_URL}/${category}/${invoiceId}`}
                  onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <div key={`page_${index + 1}`} className="pdf-page">
                      <Page
                        pageNumber={index + 1}
                        width={600 * scale}
                        size={'A4'}
                        scale={scale}
                      />
                    </div>
                  ))}
                </Document>

              </div>
            </div>
          ) : (
            <div className="col-md-6 order-md-1">
              <iframe
                src={`${process.env.REACT_APP_AGREEMENT_URL}/${category}/${invoiceId}`}
                title="PDF"
                width="100%"
                height="600"
                frameBorder="0"
              ></iframe>
            </div>
          )}
          <div className="col-md-6 order-md-2 mt-2 mb-5">
            {!isLoading && (
              <>
                {/* make form to save with 2 inputs: Name & Position */}
                <p>If PDF Not Showing, Please Reload the page!</p>
                <div className="form-group">
                  <label htmlFor="name">Name:</label>

                  <input
                    type="text"
                    id="name"
                    className={`form-control ${nameError ? "is-invalid" : ""}`}
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setNameError(""); // Clear the error when the user makes changes
                    }}
                    placeholder="John Doe"
                  />
                  {nameError && <div className="invalid-feedback">{nameError}</div>}
                </div>
                <div className="form-group">
                  <label htmlFor="position">Position:</label>
                  <input
                    type="text"
                    id="position"
                    className={`form-control ${positionError ? "is-invalid" : ""}`}
                    value={position}
                    onChange={(e) => {
                      setPosition(e.target.value);
                      setPositionError(""); // Clear the error when the user makes changes
                    }}
                    placeholder="Director, Manager, etc.."
                  />
                  {positionError && <div className="invalid-feedback">{positionError}</div>}
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="agreementCheckbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label" htmlFor="agreementCheckbox">
                  I hereby agree with the terms and conditions written under agreement documents.
                  </label>
                </div>
                <br />
                <button onClick={() => setOpenModal(true)} className="btn" >
                  {imageURL ? "Change Signature" : "Create Signature"}
                </button>
              </>
            )}
            <br />
            {imageURL && (
              <>
                <img
                  src={imageURL}
                  alt="signature"
                  className="signature"
                  style={{ width: "300px", height: "150px" }}
                />
                <br />
                {/* <button
                onClick={download}
                style={{ padding: "5px", marginTop: "5px" }}
                className="btn mt-3"
              >
                Download
              </button> */}
                <button
                  onClick={SaveSign}
                  style={{ padding: "5px", marginTop: "5px" }}
                  className="btn mt-3 mx-3"
                >
                  Save & Submit
                </button>
              </>
            )}
            {openModel && (
              <div className="modalContainer">
                <div className="modals">
                  <div className="sigPad__penColors">
                    <p>Pen Color:</p>
                    {colors.map((color) => (
                      <span
                        key={color}
                        style={{
                          backgroundColor: color,
                          border: `${color === penColor ? `2px solid ${color}` : ""
                            }`,
                        }}
                        onClick={() => setPenColor(color)}
                      ></span>
                    ))}
                  </div>
                  <div className="sigPadContainer">
                    <SignatureCanvas
                      penColor={penColor}
                      canvasProps={{ className: "sigCanvas" }}
                      ref={sigCanvas}
                    />
                    <hr />
                    <button
                      onClick={() => sigCanvas.current.clear()}
                      className="btn"
                    >
                      Clear
                    </button>
                  </div>

                  <div className="modal__bottom">
                    <button onClick={() => setOpenModal(false)} className="btn">
                      Cancel
                    </button>
                    <button className="create btn" onClick={create}>
                      Create
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SignPage;
