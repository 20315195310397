import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "react-step-progress-bar/styles.css";
import Swal from "sweetalert2";
import * as Yup from "yup";
import ProgressStepBar from "../../components/ProgressBar";
import { apiCaller } from "../../services/apiCaller";
import "./index.css";

function FormPage(props) {
  let { state } = useLocation();
  let navigate = useNavigate();
  let [contactAmount, setContactAmount] = useState(0);
  let [showContact, setShowContact] = useState([1]);
  let [data, setData] = useState({});
  let [windowWidth, setWindowWidth] = useState(window.innerWidth);
  let [selectedOptions, setSelectedOptions] = useState([]);

  const Form = () => {
    const handleOptionChange = (e, index) => {
      const newSelectedOptions = [...selectedOptions];
      newSelectedOptions[index] = e.target.value;
      setSelectedOptions(newSelectedOptions);

      const updateContactInfo = formik.values.contact_info.map((contact, i) => {
        if (i === index) {
          if (e.target.value == "whatsapp") {
            return {
              ...contact,
              otherContactEmail: "",
            };
          } else if (e.target.value == "email") {
            return {
              ...contact,
              otherContactWhatsapp: "",
            };
          }
        }

        return contact;
      });

      formik.setValues({
        ...formik.values,
        contact_info: updateContactInfo,
      });
    };

    function addContactPerson(e) {
      e.preventDefault();

      if (showContact[showContact.length - 1] + 1 > contactAmount) {
        console.log("Max contact reached");
        Swal.fire("Max contact reached", "", "warning");
      } else {
        const newShowContact = [
          ...showContact,
          showContact[showContact.length - 1] + 1,
        ];
        console.log(newShowContact);
        setShowContact(newShowContact);
      }
    }

    const initialContactInfo = Array.from({ length: contactAmount }, () => ({
      name: "",
      phone: "",
      caller: "",
    }));

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        name: data?.full_name,
        company_name: data?.company_name,
        address: data?.address,
        phone: data?.phone,
        company_business: "",
        email: data?.email,
        employees: "",
        contact_info: initialContactInfo,
        mail_handling: {
          email: "",
          whatsapp: "",
        },
        other_contact: {
          name: "",
          phone: "",
          whatsapp: "",
          email: "",
        },
      },

      validationSchema: Yup.object().shape({
        name: Yup.string()
          .required("Full Name is required")
          .matches(/^[a-zA-Z ]*$/, "Must be an Alphabet!"),
        company_name: Yup.string().required("Company name is required!"),
        address: Yup.string().required("Address is required!"),
        phone: Yup.number("Phone number must be a number!")
          .required("Phone Number is required")
          .integer("Phone Number must be a number")
          .positive("Phone Number must be a number")
          .typeError("Phone Number must be a number"),
        company_business: Yup.string().required(
          "Company business is required!"
        ),
        email: Yup.string()
          .required("Email are required")
          .email("Must be an Email Format!"),
        employees: Yup.number("Number of employees must be a number!")
          .integer("Number of employees must be a number")
          .positive("Number of employees must be a number")
          .typeError("Number of employees must be a number")
          .required("Number of employees are required"),
        contact_info: Yup.array().of(
          Yup.object().shape({
            name: Yup.string()
              // .required("Name is required")
              .matches(/^[a-zA-Z ]*$/, "Must be an Alphabet!"),
            phone: Yup.number("Phone number must be a number!")
              .integer("Phone Number must be a number")
              .positive("Phone Number must be a number")
              .typeError("Phone Number must be a number"),
            otherContactEmail: Yup.string()
              .email("Must be an Email Format!")
              .optional(),
            otherContactWhatsapp: Yup.number(
              "Whatsapp number must be a number!"
            )
              .integer("Phone Number must be a number")
              .positive("Phone Number must be a number")
              .typeError("Phone Number must be a number")
              .optional(),
            caller: Yup.mixed(),
            // .required("Please check one of the following options"),
          })
        ),
        mail_handling: Yup.object().shape({
          email: Yup.string()
            .required("Email is required")
            .email("Must be an Email Format!"),
          whatsapp: Yup.number("Whatsapp number must be a number!")
            .integer("Whatsapp Number must be a number")
            .positive("Whatsapp Number must be a number")
            .typeError("Whatsapp Number must be a number")
            .required("Whatsapp Number are required"),
        }),
        other_contact: Yup.object().shape({
          name: Yup.string()
            .required("Full Name is required")
            .matches(/^[a-zA-Z ]*$/, "Must be an Alphabet!"),
          phone: Yup.number("Phone number must be a number")
            .required("Phone number are required")
            .integer("Phone number must be a number")
            .positive("Phone number must be a number")
            .typeError("Phone number must be a number"),
          whatsapp: Yup.number("Whatsapp number must be a number!")
            .integer("Whatsapp Number must be a number")
            .positive("Whatsapp Number must be a number")
            .typeError("Whatsapp Number must be a number")
            .required("Whatsapp Number are required"),
          email: Yup.string("Email address must be a string")
            .required("Email address are required")
            .email("Email address must be an email format"),
        }),
      }),

      onSubmit: (values) => {
        let obj = {
          invoice_id: state.invoice_id,
          signature: state.signature,
          pdfLocation: state.pdfLocation,
          ...values,
        };

        console.log(obj);
        navigate("/sign/review", { state: obj });
      },
    });

    if (contactAmount === null || contactAmount === 0) {
      return <div>Loading...</div>;
    }

    return (
      <form onSubmit={formik.handleSubmit}>
        <div className="form-info">
          {/* GENERAL INFORMATION */}
          <div>
            <h5 className="d-flex justify-content-start">
              A. GENERAL INFORMATION
            </h5>
            <div className="row">
              <div className="col-md">
                {/* Full name */}
                <div className="form-group">
                  <label htmlFor="name" className="float-start my-2 form-label">
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your full name"
                    {...formik.getFieldProps("name")}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="text-danger mt-1">{formik.errors.name}</div>
                  )}
                </div>
                {/* Company name */}
                <div className="form-group">
                  <label htmlFor="name" className="float-start my-2 form-label">
                    Company Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your company name"
                    {...formik.getFieldProps("company_name")}
                  />
                  {formik.touched.company_name &&
                    formik.errors.company_name && (
                      <div className="text-danger mt-1">
                        {formik.errors.company_name}
                      </div>
                    )}
                </div>
                {/* Phone */}
                <div className="form-group">
                  <label htmlFor="name" className="float-start my-2 form-label">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your phone number"
                    {...formik.getFieldProps("phone")}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <div className="text-danger mt-1">
                      {formik.errors.phone}
                    </div>
                  )}
                </div>
                {/* Email */}
                <div className="form-group">
                  <label htmlFor="name" className="float-start my-2 form-label">
                    Email Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your Email Address"
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="text-danger mt-1">
                      {formik.errors.email}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md">
                {/* address */}
                <div className="form-group">
                  <label htmlFor="name" className="float-start my-2 form-label">
                    Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your Business Address"
                    {...formik.getFieldProps("address")}
                  />
                  {formik.touched.address && formik.errors.address && (
                    <div className="text-danger mt-1">
                      {formik.errors.address}
                    </div>
                  )}
                </div>
                {/* company_business Number */}
                <div className="form-group">
                  <label htmlFor="name" className="float-start my-2 form-label">
                    Company Business Activity
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your company business"
                    {...formik.getFieldProps("company_business")}
                  />
                  {formik.touched.company_business &&
                    formik.errors.company_business && (
                      <div className="text-danger mt-1">
                        {formik.errors.company_business}
                      </div>
                    )}
                </div>
                {/* Number of Employees */}
                <div className="form-group">
                  <label htmlFor="name" className="float-start my-2 form-label">
                    Number of Employees
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your Number of employees"
                    {...formik.getFieldProps("employees")}
                  />
                  {formik.touched.employees && formik.errors.employees && (
                    <div className="text-danger mt-1">
                      {formik.errors.employees}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* CALL HANDLING */}
          <div>
            <h5 className="d-flex justify-content-start mt-3">
              B. CALL HANDLING
            </h5>
            <p className="d-flex justify-content-start">
              you can only fill up to {contactAmount} contact person
            </p>
            {showContact.map((contact, index) => (
              <div key={contact.name} className={index > 0 ? "mt-3 mb-3" : ""}>
                <h5 className="text-center" style={{ marginBottom: "0px" }}>
                  Contact Person {index + 1}
                </h5>
                <a
                  className="d-flex justify-content-end mt-1"
                  style={{ textDecoration: "none", cursor: "pointer" }}
                  onClick={addContactPerson}
                >
                  Add new contact person
                </a>
                {/* <a
                href=""
                className="d-flex justify-content-end"
                style={{ textDecoration: "none" }}
                onClick={(e) => removeContactPerson(e, index)}
              >
                Delete this contact person
              </a> */}
                <div className="row">
                  {/* Contact Person Name */}
                  <div className="col-md">
                    <div className="form-group">
                      <label
                        htmlFor={`contact_info[${index}].name`}
                        className="float-start my-2 form-label"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your contact name"
                        {...formik.getFieldProps(`contact_info[${index}].name`)}
                      />
                      {formik.touched.contact_info?.[index]?.phone &&
                        formik.errors.contact_info &&
                        formik.errors.contact_info[index] &&
                        formik.errors.contact_info[index].name && (
                          <div className="text-danger float-start mt-1">
                            {formik.errors.contact_info[index].name}
                          </div>
                        )}
                    </div>
                  </div>
                  {/* Contact Person Phone Number */}
                  <div className="col-md">
                    <div className="form-group">
                      <label
                        htmlFor={`contact_info[${index}].phone`}
                        className="float-start my-2"
                      >
                        Phone Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your contact phone number"
                        {...formik.getFieldProps(
                          `contact_info[${index}].phone`
                        )}
                      />
                      {formik.touched.contact_info?.[index] &&
                        formik.errors.contact_info &&
                        formik.errors.contact_info[index] &&
                        formik.errors.contact_info[index].phone && (
                          <div className="text-danger float-start mt-1">
                            {formik.errors.contact_info[index].phone}
                          </div>
                        )}
                    </div>
                  </div>
                </div>

                <p className="mt-3" style={{ marginBottom: "10px" }}>
                  If you are not reachable, we will forward the message to you
                  by:
                </p>
                <div className="row">
                  <div className="col-lg-5">
                    <select
                      name="otherOptions"
                      id="otherOptions"
                      className="form-select mb-2"
                      onChange={(e) => handleOptionChange(e, index)}
                    >
                      <option value="whatsapp">Whatsapp</option>
                      <option value="email" selected>
                        Email
                      </option>
                    </select>
                  </div>
                  <div className="col">
                    {selectedOptions[index] === "whatsapp" && (
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your Whatsapp number"
                          {...formik.getFieldProps(
                            `contact_info[${index}].otherContactWhatsapp`
                          )}
                        />
                        {formik.touched.contact_info?.[index] &&
                          formik.errors.contact_info &&
                          formik.errors.contact_info[index] &&
                          formik.errors.contact_info[index]
                            .otherContactWhatsapp && (
                            <div className="text-danger mt-1">
                              {
                                formik.errors.contact_info[index]
                                  .otherContactWhatsapp
                              }
                            </div>
                          )}
                      </div>
                    )}

                    {selectedOptions[index] === "email" && (
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your Email Address"
                          {...formik.getFieldProps(
                            `contact_info[${index}].otherContactEmail`
                          )}
                        />
                        {formik.touched.contact_info?.[index] &&
                          formik.touched.contact_info[index] &&
                          formik.errors.contact_info &&
                          formik.errors.contact_info[index] &&
                          formik.errors.contact_info[index]
                            .otherContactEmail && (
                            <div className="text-danger mt-1">
                              {
                                formik.errors.contact_info[index]
                                  .otherContactEmail
                              }
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                </div>
                {/* Contact Person Radio Group */}
                <div className="mt-3">
                  <p style={{ marginBottom: "7px" }}>
                    Can we give the caller your other contact number if they ask
                  </p>
                  <div className="row justify-content-md-center">
                    <div className="col-md-auto col">
                      <input
                        type="radio"
                        name={`contact_info[${index}].caller`}
                        id={`callerYes${index}`}
                        value="Yes"
                        checked={
                          formik.values.contact_info[index]?.caller === "Yes"
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        class="form-check-input"
                      />
                      <label
                        htmlFor={`callerYes${index}`}
                        class="form-check-label"
                        style={{ marginLeft: "3px" }}
                      >
                        Yes
                      </label>
                    </div>
                    <div className="col-md-auto col">
                      <input
                        type="radio"
                        name={`contact_info[${index}].caller`}
                        id={`callerNo${index}`}
                        value="No"
                        checked={
                          formik.values.contact_info[index]?.caller === "No"
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        class="form-check-input"
                      />
                      <label
                        htmlFor={`callerNo${index}`}
                        class="form-check-label"
                        style={{ marginLeft: "3px" }}
                      >
                        No
                      </label>
                    </div>
                  </div>
                  {formik.touched.contact_info?.[index] &&
                    formik.touched.contact_info[index] &&
                    formik.errors.contact_info &&
                    formik.errors.contact_info[index] &&
                    formik.errors.contact_info[index].caller && (
                      <div className="text-danger mt-1">
                        {formik.errors.contact_info[index].caller}
                      </div>
                    )}
                </div>
              </div>
            ))}
          </div>

          {/* MAIL HANDLING */}
          <div className="mb-4">
            <h5 className="d-flex justify-content-start mt-3">
              C. MAIL HANDLING
            </h5>

            <div className="row">
              <div className="col-md">
                {/* email */}
                <div className="form-group">
                  <label
                    htmlFor="mail_handling.email"
                    className="float-start form-label mt-2"
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your email address"
                    {...formik.getFieldProps("mail_handling.email")}
                  />
                  {formik.touched.mail_handling?.email &&
                    formik.touched.mail_handling?.email &&
                    formik.errors.mail_handling &&
                    formik.errors.mail_handling?.email &&
                    formik.errors.mail_handling?.email && (
                      <div className="text-danger mt-1">
                        {formik.errors.mail_handling?.email}
                      </div>
                    )}
                </div>
              </div>
              <div className="col-md">
                <div className="form-group">
                  <label
                    htmlFor="mail_handling.whatsapp"
                    className="float-start form-label mt-2"
                  >
                    Whatsapp
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your Whatsapp Number"
                    {...formik.getFieldProps(`mail_handling.whatsapp`)}
                  />
                  {formik.touched.mail_handling?.whatsapp &&
                    formik.touched.mail_handling?.whatsapp &&
                    formik.errors.mail_handling &&
                    formik.errors.mail_handling?.whatsapp &&
                    formik.errors.mail_handling?.whatsapp && (
                      <div className="text-danger mt-1">
                        {formik.errors.mail_handling?.whatsapp}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>

          {/* OTHER CONTACT PERSON */}
          <div>
            <h5 className="d-flex justify-content-start mt-3">
              D. OTHER CONTACT PERSON
            </h5>

            <div className="row">
              <div className="col-md">
                <div className="form-group mt-2">
                  <label
                    htmlFor="other_contact.name"
                    className="float-start form-label"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your name"
                    {...formik.getFieldProps("other_contact.name")}
                  />
                  {formik.touched.other_contact?.name &&
                    formik.touched.other_contact?.name &&
                    formik.errors.other_contact &&
                    formik.errors.other_contact?.name &&
                    formik.errors.other_contact?.name && (
                      <div className="text-danger mt-1">
                        {formik.errors.other_contact?.name}
                      </div>
                    )}
                </div>

                <div className="form-group mt-2">
                  <label
                    htmlFor="other_contact.whatsapp"
                    className="float-start form-label"
                  >
                    Whatsapp Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your whatsapp number"
                    {...formik.getFieldProps("other_contact.whatsapp")}
                  />
                  {formik.touched.other_contact?.whatsapp &&
                    formik.touched.other_contact?.whatsapp &&
                    formik.errors.other_contact &&
                    formik.errors.other_contact?.whatsapp &&
                    formik.errors.other_contact?.whatsapp && (
                      <div className="text-danger mt-1">
                        {formik.errors.other_contact?.whatsapp}
                      </div>
                    )}
                </div>
              </div>
              <div className="col-md">
                <div className="form-group mt-2">
                  <label
                    htmlFor="other_contact.phone"
                    className="float-start form-label"
                  >
                    Phone Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your phone number"
                    {...formik.getFieldProps("other_contact.phone")}
                  />
                  {formik.touched.other_contact?.phone &&
                    formik.touched.other_contact?.phone &&
                    formik.errors.other_contact &&
                    formik.errors.other_contact?.phone &&
                    formik.errors.other_contact?.phone && (
                      <div className="text-danger mt-1">
                        {formik.errors.other_contact?.phone}
                      </div>
                    )}
                </div>

                <div className="form-group">
                  <label
                    htmlFor="other_contact.email"
                    className="float-start form-label"
                  >
                    Email Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your Email Address"
                    {...formik.getFieldProps("other_contact.email")}
                  />
                  {formik.touched.other_contact?.email &&
                    formik.touched.other_contact?.email &&
                    formik.errors.other_contact &&
                    formik.errors.other_contact?.email &&
                    formik.errors.other_contact?.email && (
                      <div className="text-danger mt-1">
                        {formik.errors.other_contact?.email}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          style={{ padding: "5px", marginTop: "5px" }}
          className="btn mt-3 mx-3"
          type="submit"
        >
          Save & Review
        </button>
      </form>
    );
  };

  useEffect(() => {
    console.log(windowWidth);
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    Swal.fire({
      title: "Please Wait",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    apiCaller
      .get(`/getDetails/${state.invoice_id}`)
      .then((res) => {
        setContactAmount(res.data.plan_1.max_contacts);
        setData(res.data);
        const initialSelectedOptions = Array.from(
          { length: res.data.plan_1.max_contacts },
          () => "email"
        );

        console.log(res.data);
        setSelectedOptions(initialSelectedOptions);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        Swal.close();
      });

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="container">
        <ProgressStepBar currentStep={2} totalSteps={3} />
        <div className="row">
          <div className="col-md-6">
            <iframe
              src={`${process.env.REACT_APP_PDF_URL}/${state.invoice_id}.pdf`}
              className="iframe"
            ></iframe>
          </div>
          <div className="col-md-6 mb-3">
            <div
              className="form-section p-3 border rounded d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "white" }}
            >
              {Form()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FormPage;
